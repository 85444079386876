import React from 'react';
import SuccessSection from 'common/src/components/Custom/Success';
import { ResetCSS } from 'common/src/assets/css/style';

const SuccessPage = () => (
  <>
    <ResetCSS />
    <SuccessSection></SuccessSection>
  </>
);

export default SuccessPage;
